import React from "react";
import "./App.css";
import image from "./assets/images/the-garden.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-logo">NFG</h1>
      </header>
      <a
        className="App-link"
        title="Link to the 'The Garden' NFT on the OpenSea marketplace"
        href="https://opensea.io/assets/ethereum/0x6f76591578c0f843788bd348aabd3b01869b2daa/1"
      >
        <img className="App-image" src={image} alt="Art for 'The Garden' NFT" />
      </a>
      <div></div>
    </div>
  );
}

export default App;
